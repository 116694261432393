<template>
  <div class="center-content full-screen">
    <LoginBox/>
  </div>
</template>

<script>
import LoginBox from '../src/components/LoginBox'

export default {
  name: 'LoginScreen',
  components: {
    LoginBox
  }
}
</script>

<style scoped>

</style>